<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/T.G_Hero-9.jpg');"
      >
      </parallax>
      <div class="content-center brand">
        <img src="img/trace_global-w.svg" height="300" alt="" />
        <!-- <h1 class="h1-seo" >Trace.Global</h1>
        <h3>Transparency and purpose</h3> -->
      </div>
    </div>
    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Who we are?</h2>
            <h5 class="description">
              Trace.Global deploys storytelling and traceability to connect communities 
              and products to global markets. By presenting transparent and verifiable 
              stories we empower organizations to add value and improve people's social 
              and economic conditions.
            </h5>
          </div>
        </div>
        <div class="separator separator-primary"></div>
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-6">
              <div
                class="image-container image-left"
                style="background-image: url('img/T.G_Hero-3.jpg')"
              >
                <!-- First image on the left side -->
                <p class="blockquote blockquote-primary">
                  Organizations need to ensure verifiable impact and compliance.
                  <br />
                  <br />
                  Consumers want to make better informed purchase decisions. 
                  They want to feel connected and want to make a difference.
                </p>
              </div>
              <!-- Second image on the left side of the article -->
              <!-- <div
                class="image-container"
                style="background-image: url('img/bg3.jpg')"
              ></div> -->
            </div>
            <div class="col-md-5">
              <!-- First image on the right side, above the article -->
              <div
                class="image-container image-right"
                style="background-image: url('img/T.G_Hero-12.jpg')"
              ></div>
              <h3>
                Enabling transparency, trust and connection
              </h3>
              <p>
                <strong>Storytelling:</strong> Trace.Global offers a scalable automated digital 
                content collection and publishing platform to allow dynamic sharing of a product 
                or a project story.
              </p>
              <p>
                <strong>Traceability:</strong> Trace.Global combines mobile apps, IoT, AI, 
                blockchain and data analytics to create flexible, cost-efficient and easy to 
                deploy traceability of products and projects.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="section section-team text-center">
      <div class="container">
        <h2 class="title">Here is our team</h2>
        <div class="team">
          <div class="row">
            <div class="col-md-4">
              <div class="team-player">
                <img
                  src="img/avatar.jpg"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">Romina Hadid</h4>
                <p class="category text-primary">Model</p>
                <p class="description">
                  You can write here details about one of your team members. You
                  can give more details about what they do. Feel free to add
                  some <a href="#">links</a> for people to be able to follow
                  them outside the site.
                </p>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-twitter"></i
                ></a>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-instagram"></i
                ></a>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-facebook-square"></i
                ></a>
              </div>
            </div>
            <div class="col-md-4">
              <div class="team-player">
                <img
                  src="img/ryan.jpg"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">Ryan Tompson</h4>
                <p class="category text-primary">Designer</p>
                <p class="description">
                  You can write here details about one of your team members. You
                  can give more details about what they do. Feel free to add
                  some <a href="#">links</a> for people to be able to follow
                  them outside the site.
                </p>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-twitter"></i
                ></a>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-linkedin"></i
                ></a>
              </div>
            </div>
            <div class="col-md-4">
              <div class="team-player">
                <img
                  src="img/eva.jpg"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">Eva Jenner</h4>
                <p class="category text-primary">Fashion</p>
                <p class="description">
                  You can write here details about one of your team members. You
                  can give more details about what they do. Feel free to add
                  some <a href="#">links</a> for people to be able to follow
                  them outside the site.
                </p>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-google-plus"></i
                ></a>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-youtube"></i
                ></a>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-twitter"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="section section-contact-us text-center">
      <div class="container">
        <h2 class="title">Let's keep making change happen together</h2>
        <p class="description">Get in touch!</p>
        <div v-if="contactFormVisible" class="row">
          <div class="col-lg-6 text-center ml-auto mr-auto col-md-8">
            <fg-input
              class="input-lg"
              placeholder="Name*"
              v-model="form.name"
              required
              addon-left-icon="now-ui-icons users_circle-08"
            >
            </fg-input>
            <fg-input
              class="input-lg"
              placeholder="Country"
              v-model="form.country"
              addon-left-icon="now-ui-icons location_world"
            >
            </fg-input>
            <fg-input
              class="input-lg"
              placeholder="Email*"
              v-model="form.email"
              required
              addon-left-icon="now-ui-icons ui-1_email-85"
            >
            </fg-input>
            <fg-input
              class="input-lg"
              placeholder="Phone"
              v-model="form.phone"
              addon-left-icon="now-ui-icons tech_mobile"
            >
            </fg-input>
            <fg-input
              class="input-lg"
              placeholder="Website"
              v-model="form.website"
              addon-left-icon="now-ui-icons design-2_html5"
            >
            </fg-input>
            <div class="textarea-container">
              <textarea
                class="form-control"
                name="name"
                rows="4"
                cols="80"
                v-model="form.message"
                placeholder="Tell us about your project, plans and timeline"
              ></textarea>
            </div>
            <input type="input" name="hp" value="" style="display: none" tabindex="-1" autocomplete="off">

            <div class="send-button">
              <n-button v-if="!contactMessageInProcess" type="primary" :disabled="!form.name || !form.email" round block size="lg" @click="handleSendContactMessage">
                Send Message
              </n-button>
            </div>
          </div>
        </div>
          <alert v-if="contactMessageInProcess" type="info" >
            <div class="alert-icon">
              <i class="now-ui-icons travel_info"></i>
            </div>
            <strong>Sending message ...</strong>
          </alert>
          <alert :visible="contactAlertVisible" type="success" dismissible @dismiss="handleContactAlertDismiss">
            <div class="alert-icon">
              <i class="now-ui-icons ui-2_like"></i>
            </div>
            <strong>Message sent, thanks!</strong> We'll get in touch soon.
          </alert>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, FormGroupInput } from '@/components'
import {Alert} from '@/components'
import axios from 'axios'

export default {
  name: 'landing',
  bodyClass: 'landing-page',
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    [Alert.name]: Alert
  },
  data() {
    return {
      form: {
        name: '',
        email: '',
        message: ''
      },
      contactAlertVisible: false,
      contactMessageInProcess: false,
      contactFormVisible: true
    };
  },
  methods: {
    handleSendContactMessage(evt) {
      this.contactMessageInProcess = true
      this.contactFormVisible = false
      this.form['subject'] = "Trace.Global website contact message"
      axios.post('https://7lmywmtwhi.execute-api.us-west-2.amazonaws.com/prod/contact', 
        this.form, 
        {
          headers: {
            'content-type': 'application/json'
          }
        }
      )
      .then(response => {
        //console.log(`Response: \n${JSON.stringify(response, null, 2)}`)
        this.form.name = ''
        this.form.email = ''
        this.form.message = ''
        this.contactMessageInProcess = false
        this.contactAlertVisible = true
      })

    },
    handleContactAlertDismiss(evt) {
      this.contactAlertVisible = false
      this.contactFormVisible = true
    }
  }
};
</script>
<style></style>
